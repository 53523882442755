module.exports = [{
      plugin: require('/vercel/path0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"EricLi","short_name":"EricLi","start_url":"/","background_color":"#020c1b","theme_color":"#0a192f","display":"minimal-ui"},
    },{
      plugin: require('/vercel/path0/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/vercel/path0/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1100,"quality":90,"linkImagesToOriginal":true},
    },{
      plugin: require('/vercel/path0/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-136071260-1"},
    },{
      plugin: require('/vercel/path0/node_modules/gatsby-plugin-vercel/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/vercel/path0/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
